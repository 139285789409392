
import './App.css';
import Chat from './components/Chat/Chat';
function App() {
  return (
    <div className="">
      <Chat/>
    </div>
  );
}

export default App;
